import React from "react";

import { Container } from "react-bootstrap";

function Usocookies() {

  return (
    <section>
        <Container fluid className="home-section" id="home">
            Quizas para las cookies
        </Container>
    </section>
  );
}
export default Usocookies;